//Reset CSS
html, body, body #root, #root > div.App {
    min-height: 100vh;
}
#global_content {
    min-height: 300px;
    padding-top: 15px;
}
.sd_loader {
    width: 70px;
    height: 70px;
    margin: 0px auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &.align_left {
        margin-left: 0px;
        text-align: left;
        justify-content: flex-start;
    }
    &.align_right {
        margin-right: 0px;
        text-align: right;
        justify-content: flex-end;
    }
}
#root > .sd_loader {    
    margin: auto;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;    
}
.app_debug_info {
    margin-top: 15px;
    li {
        margin-bottom: 5px;
        word-break: break-all;
    }
}
header.mui-fixed + .main-container{
    padding-top: 53px;
}
.layout_page_profile.user_authorized,
.App.app_transparent_header {
    header.mui-fixed + .main-container{
        padding-top: 0px;
    }
    #global_content {
        padding-top: 0px;
    }
}
.App.layout_page_search {
    padding-bottom: 55px;
    overflow: hidden;
    .header-logo {
        display: none;
    }
}
.text-center{
    text-align: center;
}
html.overflow_hidden, html.overflow_hidden body {
    overflow: hidden !important;
    height: 100%;
    min-height: 0px;
    position: relative;
}
html.overflow_hidden {
    div.App, #global_wrapper, #global_content, #global_content div {
        overflow: hidden;
    }
}
div.pac-container {
    z-index: 1400;
    border: none;
}
.info_tooltip_icon.MuiSvgIcon-root {
    width: 17px;
    height: 17px;
    cursor: pointer;
    vertical-align: middle;
    margin-left: 5px;
    fill: #3c4858;
}
html {
    .zEWidget-launcher,
    .zEWidget-webWidget,
    iframe#launcher,
    iframe#webWidget {
        visibility: hidden !important;
        bottom: -100px !important;
        transition: all 0.3s ease !important;
        opacity: 1 !important;
    }
    &[class*=layout_page_dashboard_help] {
        .zEWidget-launcher,
        .zEWidget-webWidget,
        iframe#launcher,
        iframe#webWidget {
            visibility: visible !important;
            bottom: 90px !important;
            z-index: 1200 !important;
            right: 16px !important;
        }
    }
}

.wobble_effect {
    animation: wobbleEffect 1.3s ease-in-out;
}

@media screen and (max-width: 667px){
    html {
        &[class*=layout_page_dashboard_help] {
            .zEWidget-launcher,
            .zEWidget-webWidget {
                bottom: 150px !important;
            }
        }
    }
}

@-webkit-keyframes nextArrow {
	0%, 100% { -webkit-transform: translateX(0px); }
	25% { -webkit-transform: translateX(1px); }
	75% { -webkit-transform: translateX(-1px); }
}
@-moz-keyframes nextArrow {
	0%, 100% { -moz-transform: translateX(0px); }
	25% { -moz-transform: translateX(1px); }
	75% { -moz-transform: translateX(-1px); }
}
@keyframes nextArrow {
	0%, 100% { -moz-transform: translateX(0px);transform: translateX(0px); }
	25% { -moz-transform: translateX(1px);transform: translateX(1px); }
	75% { -moz-transform: translateX(-1px);transform: translateX(-1px); }
}

@-webkit-keyframes prevArrow {
	0%, 100% { -webkit-transform: translateX(0px); }
	25% { -webkit-transform: translateX(-1px); }
	75% { -webkit-transform: translateX(1px); }
}
@-moz-keyframes prevArrow {
	0%, 100% { -moz-transform: translateX(0px); }
	25% { -moz-transform: translateX(-1px); }
	75% { -moz-transform: translateX(1px); }
}
@keyframes prevArrow {
	0%, 100% { -moz-transform: translateX(0px);transform: translateX(0px); }
	25% { -moz-transform: translateX(-1px);transform: translateX(-1px); }
	75% { -moz-transform: translateX(1px);transform: translateX(1px); }
}
@-webkit-keyframes customSpin {
    0% { -webkit-transform:rotate(0deg)} to {-webkit-transform:rotate(1turn) }
}
@-moz-keyframes customSpin {
    0% { -moz-transform:rotate(0deg)} to {-moz-transform:rotate(1turn) }
}
@keyframes customSpin {
	0% { transform:rotate(0deg)} to {transform:rotate(1turn) }
}
@-webkit-keyframes wobbleEffect {
    10%, 90% {transform: translate3d(-1px, 0, 0);}
    20%, 80% {transform: translate3d(2px, 0, 0);}
    30%, 50%, 70% {transform: translate3d(-4px, 0, 0);}
    40%, 60% {transform: translate3d(4px, 0, 0);}
}
@-moz-keyframes wobbleEffect {
    10%, 90% {transform: translate3d(-1px, 0, 0);}
    20%, 80% {transform: translate3d(2px, 0, 0);}
    30%, 50%, 70% {transform: translate3d(-4px, 0, 0);}
    40%, 60% {transform: translate3d(4px, 0, 0);}
}
@keyframes wobbleEffect {
    10%, 90% {transform: translate3d(-1px, 0, 0);}
    20%, 80% {transform: translate3d(2px, 0, 0);}
    30%, 50%, 70% {transform: translate3d(-4px, 0, 0);}
    40%, 60% {transform: translate3d(4px, 0, 0);}
}
.passwordCheck-valid-customizable {
	color: #19BF00;	
	font-size: 14px;
}
.passwordCheck-notValid-customizable {
	color: #DF3312;	
	font-size: 14px;
}
.passwordCheck-valid-customizable .validation-error-symbol,
.passwordCheck-notValid-customizable .validation-error-symbol {
	margin-right: 5px;
}